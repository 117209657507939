<template>
    <div class="toolbar_main">
        <div class="osahan-breadcrumb">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <ol class="breadcrumb">
                            <li class="breadcrumb-item"><a :href="'/tienda/'+sucursal_seleccionada"><i class="icofont icofont-ui-home"></i> Inicio</a></li>
                            <li class="breadcrumb-item active">Asesores de Venta</li>
                        </ol>
                    </div>
                </div>
            </div>
        </div>

        <section class="our-team-main">
            <div class="container" style="background-color: white;">
                <div class="section-header section-header-center text-center">
                    <h3 class="heading-design-center-h3 pt-3">Nuestros Asesores de Venta</h3>
                </div>
                <div class="row"></div>
            </div>
        </section>

        <section class="products_page" style="padding: 30px 30px 15px;">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12 col-md-8">   
                        <div class="row products_page_list">
                            <div class="clearfix"></div>

                            <div v-if="vendedores.length>0" class="col-lg-3 col-md-6 col-sm-6" v-for="(val, key) in vendedores" :key="key">
                                <div class="item">
                                    <div class="h-100">
                                        <div class="product-item">
                                            <div class=" " style="padding: 10px;">
                                                <img class="card-img-top img-fluid" style="width:235px; height: 250px;" :src="val.url_foto" alt>
                                            </div>
                                            <div class="product-item-body">
                                                <h4 class="card-title px-0 my-0 text-truncate" style="color: #343a40;font-size: 14px;font-weight: 600;">{{val.nombre_completo}}</h4>
                                                
                                                <p class="px-0 my-0 lh-1x"  ><i class="fa fa-envelope-o"></i> {{val.email??empresa.email}} </p>
                                                <p class="px-0 my-0 lh-1x " v-if="val.telefono" >
                                                    <a :href="'https://api.whatsapp.com/send?phone='+val.telefono+'&text=%F0%9F%98%80%20Hola%20Quisiera%20hacer%20una%20consulta'" target="_blank">
                                                        <i class="fa fa-whatsapp"></i> Escribame por whatsapp
                                                    </a>
                                                </p>
                                                <p v-else class="px-0 my-0 lh-1x " >
                                                    <i>Sin número whatsapp :(</i>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="vendedores.length==0" class="col-lg-12 col-md-12 col-sm-12">
                                <div class="card mb-2">
                                    <div class="card-body ">
                                        <h4 class="card-title text-center">
                                            Lo sentimos, estamos cargando información de nuestro selecto grupo de <b style="color: #a32121 ;">'ASESORES DE VENTA'</b>
                                            <center>
                                                <i class="fa fa-users" style="font-size:80px" aria-hidden="true"></i>
                                            </center>
                                            <p class="card-text  text-center "><small class="text-muted">Los asesores de venta están sujetos a sus respectivas sucursales asignados por el GERENTE GENERAL</small></p>  
                                        </h4> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
    import {mapState, mapMutations, mapActions} from 'vuex';

    export default {
        name: 'Asesores',
        computed:{
            ...mapState(['empresa', 'mi_cuenta', 'sucursal_seleccionada']),
        },
        created(){
            this.loadObjSucursalSeleccionada();
        },
        mounted(){
            this.loadObjPerfilUsuario();
            this.cargarAsesores();
            document.title = "Asesores de ventas | "+((this.empresa.abreviatura)?this.empresa.abreviatura:"-");
        },
        data(){
            return{
                vendedores:[],
            }
        },
        methods:{
            ...mapMutations(['setLoading']),
            ...mapActions(['loadObjPerfilUsuario', 'loadObjSucursalSeleccionada']),
            cargarAsesores(){
                let selfs = this;

                selfs.setLoading(true);
                
                selfs.$http.get('tiendaVirtual/asesores_venta?sucursal='+this.sucursal_seleccionada).then((res)=>{
                    this.vendedores = res.data.vendedores;
                    selfs.setLoading(false);
                    console.log(this.vendedores.length);
                }).catch(function (e) {
                    if(e && e.response && e.response.status==422){ //Errores de Validacion
                        const mapErrors ={};
                        selfs.errorSolicitud = [];
                        selfs.input_email=false;
                        for (let field of Object.keys(e.response.data.errors)) {
                            mapErrors[field]=e.response.data.errors[field][0];
                            selfs.errorSolicitud.push(mapErrors[field]);
                        }
                    }
                });
            },
        },
    }
</script>

<style scoped>
    .lh-1x {
        line-height: 17px;
    }
</style>